import Image from "next/image";
import Link from "next/link";
import { IoMdMail } from "react-icons/io";
import { useState } from "react";
import { IoMdMailOpen } from "react-icons/io";
import { BiSolidPhoneCall } from "react-icons/bi";
import { BiSolidPhone } from "react-icons/bi";
import { AiFillInstagram } from "react-icons/ai";
import { FaFacebookF, FaLinkedinIn } from "react-icons/fa6";
import { IoLogoWhatsapp } from "react-icons/io";
import { motion } from "framer-motion";
import { useRouter } from "next/router";

const Footer = () => {
  const router = useRouter();
  const [mail, setMail] = useState(false);
  const [phone, setPhone] = useState(false);

  const handleMouseEnter = () => {
    setMail(true);
  };

  const handleMouseLeave = () => {
    setMail(false);
  };

  const handlePhoneMouseEnter = () => {
    setPhone(true);
  };

  const handlePhoneMouseLeave = () => {
    setPhone(false);
  };

  const currentYear = new Date().getFullYear();

  return (
    <div className="bg-[#1A3183]">
    <footer className="max-w-screen-xl  mx-auto relative flex flex-col px-3 lg:px-2 md:pt-20 pb-10 mt-16 md:mt-10">
      <div className="mx-auto md:mx-0 grid gap-4  min-[1150px]:gap-10 min-[1150px]:flex  items-start justify-between border-b-2 border-b-[#EAEAEA] pt-5 md:py-5">
        <div className="flex flex-col space-y-5">
          <Image
            src={"/images/logo-new-update.webp"}
            width={150}
            height={52}
            className="md:w-[150px] h-auto md:h-[40px] bg-white"
            alt="zysoftec logo"
          />
          <span className="text-base font-normal max-w-[25rem] md:max-w-full  text-white min-[1150px]:max-w-[18rem] xl:max-w-[25rem] ">
            ZYSOFTEC is a software company dedicated to providing cutting-edge
            solutions for businesses across various industries. With a focus on
            innovation and client satisfaction, we strive to deliver
            high-quality software products and services.
          </span>
        </div>
        <div className="flex flex-col   space-y-4 items-start  md:mt-0 fontNew">
          <span className="text-xl text-white font-medium">
            About ZySoftec
          </span>
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 md:gap-2 fontNew">
            <div>
              <span
                className="text-base font-bold   hover:text-[#E05959]  text-white  cursor-pointer transition-all duration-500 "
                onClick={() => router.push("/")}
              >
                Home
              </span>
            </div>

            <div>
              <span
                className="text-base font-bold text-white hover:text-[#E05959]   cursor-pointer transition-all duration-500 "
                onClick={() => router.push("/services")}
              >
                Services
              </span>
            </div>

            <div>
              <span
                className="text-base font-bold text-white  hover:text-[#E05959]   cursor-pointer transition-all duration-500 "
                onClick={() => router.push("/about-us")}
              >
                About us
              </span>
            </div>

            {/* <Link
              href={"/solution"}
              className="text-base font-bold"
            >
              Solution
            </Link> */}
            <div>
              <span
                className="text-base font-bold  hover:text-[#E05959]  text-white  cursor-pointer transition-all duration-500 "
                onClick={() => router.push("/case-study")}
              >
                Portfolio
              </span>
            </div>

            <div>
              <span
                className="text-base font-bold  hover:text-[#E05959] text-white   cursor-pointer transition-all duration-500 "
                onClick={() => router.push("/contact-us")}
              >
                Contact us
              </span>
            </div>

            <div>
              <span
                className="text-base font-bold  hover:text-[#E05959] text-white   cursor-pointer transition-all duration-500 "
                onClick={() => router.push("/how-it-works")}
              >
                How it Works
              </span>
            </div>

            <div>
              <span
                className="text-base font-bold  hover:text-[#E05959] text-white   cursor-pointer transition-all duration-500 "
                onClick={() => router.push("/privacy-policy")}
              >
                Privacy Policy
              </span>
            </div>

            <div>
              <span
                className="text-base font-bold  hover:text-[#E05959] text-white   cursor-pointer transition-all duration-500 "
                onClick={() => router.push("/terms-and-services")}
              >
                Terms of Service
              </span>
            </div>

            <div>
              <span
                className="text-base font-bold  hover:text-[#E05959] text-white   cursor-pointer transition-all duration-500 "
                onClick={() => router.push("/refund-policy")}
              >
                Refund Policy
              </span>
            </div>

            <div>
              <span
                className="text-base font-bold  hover:text-[#E05959] text-white   cursor-pointer transition-all duration-500 "
                onClick={() => router.push("/get-quote")}
              >
                Get a Quote
              </span>
            </div>

            <div>
              <span
                className="text-base font-bold  hover:text-[#E05959]  text-white  cursor-pointer transition-all duration-500 "
                onClick={() => router.push("/blogs")}
              >
                Blogs
              </span>
            </div>

            <div>
              <span
                className="text-base font-bold  hover:text-[#E05959] text-white   cursor-pointer transition-all duration-500 "
                onClick={() => router.push("/book")}
              >
                Book an Appointment
              </span>
            </div>
          </div>
        </div>
        <div className="flex flex-col  space-y-4  items-start  mb-4 md:mt-0">
          <span className="text-xl text-white font-medium text-white ">Contact Us</span>
          <div className="flex flex-col">
            <div className="flex flex-col space-y-2 md:space-y-4 text-base">
              <div
                className="flex items-center space-x-2"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                {/* <Image
                  className="h-4 w-5 md:h-5 md:w-3"
                  src={mail}
                  alt="zysoftec"
                /> */}

                <Link
                  href={"mailto:hello@zysoftec.com"}
                  className="fontNew  text-white  flex items-center gap-2 "
                >
                  {mail ? (
                    <IoMdMailOpen className=" text-[#FA7071]  text-[20px] " />
                  ) : (
                    <IoMdMail className=" text-[20px] text-[#FA7071] " />
                  )}
                  hello@zysoftec.com
                </Link>
              </div>

              <div
                className="flex items-center space-x-2"
                onMouseEnter={handlePhoneMouseEnter}
                onMouseLeave={handlePhoneMouseLeave}
              >
                <Link
                  href={"https://wa.me/3175682252"}
                  className="fontNew flex items-center gap-1 text-white "
                  target="_blank"
                >
                  {phone ? (
                    <BiSolidPhoneCall className="text-[#183394] text-[#FA7071]  text-[24px] " />
                  ) : (
                    <BiSolidPhone className="text-[#183394] text-[24px] text-[#FA7071] " />
                  )}
                  +92 3175682252
                </Link>
              </div>
            </div>
            <div className="flex items-start space-x-2 mt-6 md:mt-6">
              <Link
                href="https://www.facebook.com/ZySoftec?mibextid=ZbWKwL"
                target="_blank"
              >
                <motion.div
                  whileTap={{ scale: 0.8 }}
                  className="bg-[#183394] hover:scale-110 transition-all duration-300 w-[32px] h-[32px] flex items-center justify-center rounded-full "
                >
                  <FaFacebookF className="text-white hover:text-[#FA7071] text-[16px]" />
                </motion.div>
              </Link>
              <Link
                href="https://www.linkedin.com/in/zysoftec-online-software-development-agency-003b0a263"
                target="_blank"
              >
                <motion.div
                  whileTap={{ scale: 0.8 }}
                  className="bg-[#183394] w-[32px] h-[32px] hover:scale-110 transition-all duration-300 flex items-center justify-center rounded-full "
                >
                  <FaLinkedinIn className="text-white hover:text-[#FA7071] text-[16px] " />
                </motion.div>
              </Link>
              <Link
                href="https://instagram.com/zysoftec?igshid=NTc4MTIwNjQ2YQ=="
                target="_blank"
              >
                <motion.div
                  whileTap={{ scale: 0.8 }}
                  className="bg-[#183394] w-[32px] h-[32px] hover:scale-110 transition-all duration-300 flex items-center justify-center rounded-full "
                >
                  <AiFillInstagram className="text-white hover:text-[#FA7071] text-[16px] " />
                </motion.div>
              </Link>
              <Link href={"https://wa.me/3175682252"} target="_blank">
                <motion.div
                  whileTap={{ scale: 0.8 }}
                  className="bg-[#183394] w-[32px] h-[32px] hover:scale-110 transition-all duration-300 flex items-center justify-center rounded-full "
                >
                  <IoLogoWhatsapp className="text-white hover:text-[#FA7071] text-[16px] " />
                </motion.div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col  md:flex-row items-center justify-between pt-10">
        <span className="text-xs md:text-base font-normal text-white ">
          © {currentYear} ZySoftec, All rights reserved.
        </span>
        <div className="flex items-center space-x-2">
          <span className="text-xs md:text-base text-white  font-normal">Powered By</span>
          <span className="text-base font-normal text-white">ZySoftec</span>
        </div>
      </div>
      <Image
        src={"/images/footer-circle.svg"}
        width={151}
        height={151}
        className="absolute left-10 top-10 -z-10"
        alt="footer"
        priority
      />
    </footer>
    </div>
  );
};

export default Footer;
